import React, { useReducer, createContext, useEffect } from "react";
import { message } from "antd";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Team } from "../models";
import { updateTeamList } from "../actions";

const TeamStateContext = createContext();
const TeamDispatchContext = createContext();

const teamsReducer = (state, action) => {
	switch (action.type) {
		case "ADD_TEAM":
			let team = {
				espnId: action.espnId,
				school: action.school,
				mascot: action.mascot,
				abbrev: action.abbrev,
				conference: action.conference,
			};

			if (state.find((team) => action.espnId === team.espnId)) {
				message.warning(
					`${action.espnId}-${action.school} already exits.`
				);
			} else {
				addTeam(team);
			}

			return state;

		case "UPDATE_TEAM_LIST":
			return [...action.teams];
		default:
			throw Error("provide a correct action");
	}
};

async function addTeam(team) {
	await DataStore.save(new Team({ ...team }));
	message.success(`${team.espnId}-${team.school} has been added.`);
}

const TeamProvider = ({ children }) => {
	//const [state, dispatch] = useReducer(teamsReducer, initialState);
	const [state, dispatch] = useReducer(teamsReducer, []);

	async function fetchTeams() {
		const teams = await DataStore.query(Team, Predicates.ALL);
		const sortedTeams = [...teams].sort((a, b) =>
			a.school.localeCompare(b.school)
		);
		dispatch(updateTeamList(sortedTeams));
	}

	useEffect(() => {
		fetchTeams();
		const subscription = DataStore.observe(Team).subscribe(() => {
			fetchTeams();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<TeamStateContext.Provider value={state}>
			<TeamDispatchContext.Provider value={dispatch}>
				{children}
			</TeamDispatchContext.Provider>
		</TeamStateContext.Provider>
	);
};

const useTeamState = () => {
	const teamState = React.useContext(TeamStateContext);
	if (teamState === undefined) {
		throw Error("useTeamState must be used within a TeamProvider");
	}
	return teamState;
};

const useTeamDispatch = () => {
	const teamDispatch = React.useContext(TeamDispatchContext);
	if (teamDispatch === undefined) {
		throw Error("useTeamDispatch must be used within a TeamProvider");
	}
	return teamDispatch;
};

const getTeamByEspnId = (teams, espnId) => {
	if (teams !== null) {
		return teams.find((team) => team.espnId === espnId);
	} else {
		return null;
	}
};
export { TeamProvider, useTeamState, useTeamDispatch, getTeamByEspnId };
