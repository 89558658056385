import React, { useReducer, createContext, useEffect } from "react";
import { message } from "antd";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Configurations } from "../models";
import { updateConfigList } from "../actions";

const ConfigStateContext = createContext();
const ConfigDispatchContext = createContext();

const configReducer = (state, action) => {
	switch (action.type) {
		case "ADD_CONFIG":
			let config = {
				configKey: action.configKey.toUpperCase(),
				configValue: action.configValue,
			};
			if (state.find((config) => action.configKey === config.configKey)) {
				updateConfig(config.configKey, action);
				message.warning(`${action.configKey} - already exits.`);
			} else {
				addConfig(config);
			}

			return state;
		case "UPDATE_CONFIG_LIST":
			return [...action.configurations];
		default:
			throw Error("provide a correct action");
	}
};
async function addConfig(config) {
	try {
		await DataStore.save(new Configurations({ ...config }));
		message.success(`${config.configKey} - has been added.`);
	} catch (error) {
		console.log(error);
	}
}
async function updateConfig(key, config) {
	const original = await DataStore.query(Configurations, (c) =>
		c.configKey("eq", key)
	);

	try {
		await DataStore.save(
			Configurations.copyOf(original[0], (updated) => {
				updated.configValue = config.configValue;
			})
		);
	} catch (error) {
		console.log(error);
	}
}
const ConfigProvider = ({ children }) => {
	const [state, dispatch] = useReducer(configReducer, []);

	async function fetchConfig() {
		const configurations = await DataStore.query(
			Configurations,
			Predicates.ALL
		);
		dispatch(updateConfigList(configurations));
	}

	useEffect(() => {
		fetchConfig();
		const subscription = DataStore.observe(Configurations).subscribe(() => {
			fetchConfig();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<ConfigStateContext.Provider value={state}>
			<ConfigDispatchContext.Provider value={dispatch}>
				{children}
			</ConfigDispatchContext.Provider>
		</ConfigStateContext.Provider>
	);
};

const useConfigState = () => {
	const configState = React.useContext(ConfigStateContext);
	if (configState === undefined) {
		throw Error("useConfiState must be used within a ConfigProvider");
	}
	return configState;
};

const useConfigStateKeyValue = () => {
	const configState = React.useContext(ConfigStateContext);
	if (configState === undefined) {
		throw Error("useConfiState must be used within a ConfigProvider");
	}
	const keyValueConfig = {};
	configState.forEach(
		(config) => (keyValueConfig[config.configKey] = config.configValue)
	);
	return keyValueConfig;
};

const useConfigDispatch = () => {
	const configDispatch = React.useContext(ConfigDispatchContext);
	if (configDispatch === undefined) {
		throw Error("useConfigDispatch must be used within a ConfigProvider");
	}
	return configDispatch;
};

export {
	ConfigProvider,
	useConfigState,
	useConfigDispatch,
	useConfigStateKeyValue,
};
