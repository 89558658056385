// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Position = {
  "QB": "QB",
  "RB": "RB",
  "WR": "WR",
  "K": "K"
};

const { Team, Game, Configurations, Player, Lineup, FantasyGame, User, PlayerStats } = initSchema(schema);

export {
  Team,
  Game,
  Configurations,
  Player,
  Lineup,
  FantasyGame,
  User,
  PlayerStats,
  Position
};