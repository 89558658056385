import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { ConfigProvider } from "../context/configContext";
import { GameProvider } from "../context/gameContext";
import { TeamProvider } from "../context/teamContext";
import { PlayerProvider } from "../context/playerContext";
import { UserProvider } from "../context/userContext";

import "../css/App.css";
import "../css/big10v1.css";
import UserHeader from "./UserHeader";

const AdminLayout = lazy(() => import("./AdminLayout"));
const MainLayout = lazy(() => import("./MainLayout"));
const TeamsPage = lazy(() => import("./TeamsPage"));
const GamesPage = lazy(() => import("./GamesPage"));
const ScoreboardPage = lazy(() => import("./ScoreboardPage"));
const ConfigPage = lazy(() => import("./ConfigPage"));
const PlayerPage = lazy(() => import("./PlayerPage"));
const LineupPage = lazy(() => import("./LineupPage"));
const FantasyGameAdminPage = lazy(() => import("./FantasyGameAdminPage"));
const UsersAdminPage = lazy(() => import("./UsersAdminPage"));

function App() {
	// Create listener

	return (
		<ConfigProvider>
			<Suspense
				fallback={<div style={{ fontSize: "40px" }}>Loading ... </div>}
			>
				<UserHeader />
				<Router>
					<Switch>
						<Route exact path="/">
							<TeamProvider>
								<GameProvider>
									<ScoreboardPage />
								</GameProvider>
							</TeamProvider>
						</Route>
						<Route exact path="/lineup">
							<LineupPage />
						</Route>
						<Route exact path="/scores">
							<TeamProvider>
								<GameProvider>
									<ScoreboardPage />
								</GameProvider>
							</TeamProvider>
						</Route>
						<Route exact path="/standings">
							<MainLayout>
								<div>Standings</div>
							</MainLayout>
						</Route>
						<Route exact path="/records">
							<MainLayout>
								<div>Records</div>
							</MainLayout>
						</Route>
						<Route exact path="/admin">
							<AdminLayout>
								<div>
									<h1>Admin Section</h1>
									<Link to="/admin/teams">
										<h3>Teams</h3>
									</Link>
									<Link to="/admin/games">
										<h3>Games</h3>
									</Link>
								</div>
							</AdminLayout>
						</Route>
						<Route exact path="/admin/teams">
							<TeamProvider>
								<TeamsPage />
							</TeamProvider>
						</Route>
						<Route exact path="/admin/games">
							<GameProvider>
								<GamesPage />
							</GameProvider>
						</Route>
						<Route exact path="/admin/players">
							<PlayerProvider>
								<PlayerPage />
							</PlayerProvider>
						</Route>
						<Route exact path="/admin/configurations">
							<ConfigPage />
						</Route>
						<Route exact path="/admin/fantasy-games">
							<FantasyGameAdminPage />
						</Route>
						<Route exact path="/admin/users">
							<UserProvider>
								<UsersAdminPage />
							</UserProvider>
						</Route>
					</Switch>
				</Router>
			</Suspense>
		</ConfigProvider>
	);
}

export default withAuthenticator(App);
