export const addTeam = ({
	id,
	espnId,
	school,
	mascot,
	abbrev,
	conference,
}) => ({
	type: "ADD_TEAM",
	id,
	espnId,
	school,
	mascot,
	abbrev,
	conference,
});

export const updateTeamList = (teams = []) => ({
	type: "UPDATE_TEAM_LIST",
	teams,
});

export const addGame = ({
	id,
	gameEspnId,
	homeTeamEspnId,
	homeScore,
	awayTeamEspnId,
	awayScore,
	gameDate,
	status,
	year,
	week,
	timeofday,
}) => ({
	type: "ADD_GAME",
	id,
	gameEspnId,
	homeTeamEspnId,
	homeScore,
	awayTeamEspnId,
	awayScore,
	gameDate,
	status,
	year,
	week,
	timeofday,
});

export const addUser = ({
	id,
	userName,
	firstName,
	lastName,
	teamName,
	status,
}) => ({
	type: "ADD_USER",
	id,
	userName,
	firstName,
	lastName,
	teamName,
	status,
});

export const addConfig = ({ id, configKey, configValue }) => ({
	type: "ADD_CONFIG",
	id,
	configKey,
	configValue,
});

export const addPlayer = ({ id, espnId, name, position, year, team }) => ({
	type: "ADD_PLAYER",
	id,
	espnId,
	name,
	position,
	year,
	team,
});

export const updateGameList = (games = []) => ({
	type: "UPDATE_GAME_LIST",
	games,
});

export const updateUserList = (users = []) => ({
	type: "UPDATE_USER_LIST",
	users,
});

export const updateConfigList = (configurations = []) => ({
	type: "UPDATE_CONFIG_LIST",
	configurations,
});

export const updatePlayerList = (players = []) => ({
	type: "UPDATE_PLAYER_LIST",
	players,
});
