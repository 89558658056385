import React from "react";
import { Auth } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";

const UserHeader = () => {
	const signOut = async () => {
		try {
			await Auth.signOut();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	};

	return (
		<div id="navcontainer_login">
			User Info {Auth.user.username}{" "}
			<a
				href="/"
				onClick={() => {
					DataStore.clear();
					signOut();
				}}
			>
				Sign Out
			</a>
		</div>
	);
};

export default UserHeader;
