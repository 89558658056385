import React, { useReducer, createContext, useEffect } from "react";
import { message } from "antd";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Game, Team } from "../models";
import { updateGameList } from "../actions";

const GameStateContext = createContext();
const GameDispatchContext = createContext();

const gamesReducer = (state, action) => {
	switch (action.type) {
		case "ADD_GAME":
			let game = {
				/* Add All Fields Her*/
				gameEspnId: action.gameEspnId,
				homeTeamEspnId: action.homeTeamEspnId,
				homeScore: parseInt(action.homeScore),
				awayTeamEspnId: action.awayTeamEspnId,
				awayScore: parseInt(action.awayScore),
				gameDate: action.gameDate,
				status: action.status,
				year: parseInt(action.year),
				week: parseInt(action.week),
				timeofday: action.timeofday,
			};

			if (state.find((game) => action.gameEspnId === game.gameEspnId)) {
				message.warning(`${action.gameEspnId} - already exits.`);
			} else {
				addGame(game);
			}

			return state;

		case "UPDATE_GAME_LIST":
			return [...action.games];
		default:
			throw Error("provide a correct action");
	}
};

async function addGame(game) {
	await DataStore.save(new Game({ ...game }));
	message.success(`${game.gameEspnId} - has been added.`);
}

const GameProvider = ({ children }) => {
	//const [state, dispatch] = useReducer(gamesReducer, initialState);
	const [state, dispatch] = useReducer(gamesReducer, []);

	async function fetchGames() {
		// const games = await DataStore.query(Game, Predicates.ALL);
		try {
			const data = await Promise.all([
				DataStore.query(Game, Predicates.ALL),
				DataStore.query(Team, Predicates.ALL),
			]);

			let games = data[0];
			const teams = data[1];

			games = games.map((game) => {
				let homeTeamSchool = teams.find(
					(team) => team.espnId === game.homeTeamEspnId
				);
				let awayTeamSchool = teams.find(
					(team) => team.espnId === game.awayTeamEspnId
				);

				return {
					...game,
					homeTeamSchool: homeTeamSchool
						? homeTeamSchool.school
						: game.homeTeamEspnId,
					awayTeamSchool: awayTeamSchool
						? awayTeamSchool.school
						: game.awayTeamEspnId,
				};
			});
			const sortedGames = [...games].sort(
				(a, b) =>
					// a.status.localeCompare(b.status)
					b.year - a.year || b.week - a.week
			);

			dispatch(updateGameList(sortedGames));
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchGames();
		const subscription = DataStore.observe(Game).subscribe(() => {
			fetchGames();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<GameStateContext.Provider value={state}>
			<GameDispatchContext.Provider value={dispatch}>
				{children}
			</GameDispatchContext.Provider>
		</GameStateContext.Provider>
	);
};

const useGameState = () => {
	const gameState = React.useContext(GameStateContext);
	if (gameState === undefined) {
		throw Error("useGameState must be used within a GameProvider");
	}
	return gameState;
};

const useGameDispatch = () => {
	const gameDispatch = React.useContext(GameDispatchContext);
	if (gameDispatch === undefined) {
		throw Error("useGameDispatch must be used within a GameProvider");
	}
	return gameDispatch;
};

export { GameProvider, useGameState, useGameDispatch };
