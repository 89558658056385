import React, { useReducer, createContext, useEffect } from "react";
import { message } from "antd";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Player } from "../models";
import { updatePlayerList } from "../actions";

const PlayerStateContext = createContext();
const PlayerDispatchContext = createContext();

const playersReducer = (state, action) => {
	switch (action.type) {
		case "ADD_PLAYER":
			let player = {
				/* Add All Fields Her*/
				espnId: action.espnId,
				name: action.name,
				position: action.position,
				year: parseInt(action.year),
				team: action.team,
			};
			if (
				state.find((player) => {
					return (
						action.espnId + action.year ===
						player.espnId + player.year
					);
				})
			) {
				message.warning(
					`${action.espnId} ${action.name} - already exits.`
				);
			} else {
				addPlayer(player);
			}

			return state;

		case "UPDATE_PLAYER_LIST":
			return [...action.players];
		default:
			throw Error("provide a correct action");
	}
};

async function addPlayer(player) {
	await DataStore.save(new Player({ ...player }));
	message.success(`${player.espnId} - has been added.`);
}

const PlayerProvider = ({ children }) => {
	//const [state, dispatch] = useReducer(playersReducer, initialState);
	const [state, dispatch] = useReducer(playersReducer, []);

	async function fetchPlayers() {
		const players = await DataStore.query(Player, Predicates.ALL);
		const sortedPlayers = [...players].sort((a, b) =>
			a.espnId.localeCompare(b.esnpId)
		);
		dispatch(updatePlayerList(sortedPlayers));
	}

	useEffect(() => {
		fetchPlayers();
		const subscription = DataStore.observe(Player).subscribe(() => {
			fetchPlayers();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<PlayerStateContext.Provider value={state}>
			<PlayerDispatchContext.Provider value={dispatch}>
				{children}
			</PlayerDispatchContext.Provider>
		</PlayerStateContext.Provider>
	);
};

const usePlayerState = () => {
	const playerState = React.useContext(PlayerStateContext);
	if (playerState === undefined) {
		throw Error("usePlayerState must be used within a PlayerProvider");
	}
	return playerState;
};

const usePlayerDispatch = () => {
	const playerDispatch = React.useContext(PlayerDispatchContext);
	if (playerDispatch === undefined) {
		throw Error("usePlayerDispatch must be used within a PlayerProvider");
	}
	return playerDispatch;
};

export { PlayerProvider, usePlayerState, usePlayerDispatch };
