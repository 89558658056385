import React, { useReducer, createContext, useEffect } from "react";
import { message } from "antd";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { User } from "../models";
import { updateUserList } from "../actions";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const usersReducer = (state, action) => {
	switch (action.type) {
		case "ADD_USER":
			let user = {
				userName: action.userName,
				firstName: action.firstName,
				lastName: action.lastName,
				teamName: action.teamName,
				status: action.status,
			};

			if (state.find((user) => action.userName === user.userName)) {
				message.warning(`${action.userName} already exits.`);
			} else {
				addUser(user);
			}

			return state;

		case "UPDATE_USER_LIST":
			return [...action.users];
		default:
			throw Error("provide a correct action");
	}
};

async function addUser(user) {
	await DataStore.save(new User({ ...user }));
	message.success(`${user.userName} has been added.`);
}

const UserProvider = ({ children }) => {
	//const [state, dispatch] = useReducer(usersReducer, initialState);
	const [state, dispatch] = useReducer(usersReducer, []);

	async function fetchUsers() {
		const users = await DataStore.query(User, Predicates.ALL);
		const sortedUsers = [...users].sort((a, b) =>
			a.userName.localeCompare(b.userName)
		);
		dispatch(updateUserList(sortedUsers));
	}

	useEffect(() => {
		fetchUsers();
		const subscription = DataStore.observe(User).subscribe(() => {
			fetchUsers();
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return (
		<UserStateContext.Provider value={state}>
			<UserDispatchContext.Provider value={dispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
};

const useUserState = () => {
	const userState = React.useContext(UserStateContext);
	if (userState === undefined) {
		throw Error("useUserState must be used within a UserProvider");
	}
	return userState;
};

const useUserDispatch = () => {
	const userDispatch = React.useContext(UserDispatchContext);
	if (userDispatch === undefined) {
		throw Error("useUserDispatch must be used within a UserProvider");
	}
	return userDispatch;
};

export { UserProvider, useUserState, useUserDispatch };
